import { NavBar } from "Components/Navigation";

function Section({ title, children }) {
    return (
        <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-3">{title}</h2>
            {children}
        </section>
    );
}

function Subsection({ title, children }) {
    return (
        <div className="mb-6">
            <h3 className="text-xl font-medium mb-2">{title}</h3>
            {children}
        </div>
    );
}

export default function PrivacyPolicy() {
    return (
        <div className="h-full w-full overflow-hidden differentCursor">
            <NavBar />
            <div className="mt-[80px] z-10 px-6 py-10 max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy for Salesbox</h1>

                <Section title="1. Introduction and Scope">
                    <p>
                        Salesbox (<a href="https://salesbox.io" className="text-blue-600 underline">https://salesbox.io</a>). This Privacy Policy outlines our practices regarding the collection, use, and sharing of your personal information when you interact with our services, website, or products.
                    </p>
                    <p>
                        The purpose of this Privacy Policy is to provide you with a clear understanding of how we handle your data, ensuring transparency and compliance with applicable data protection laws.
                    </p>
                </Section>

                <Section title="2. Information Collection">
                    <Subsection title="2.1 Types of Personal Data Collected">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Basic identification information (e.g., name, email address, phone number)</li>
                            <li>Professional information (e.g., job title, company name)</li>
                            <li>Account credentials</li>
                            <li>Usage data and interaction with our services</li>
                            <li>Device and browser information</li>
                            <li>IP address and location data</li>
                            <li>Any other information you choose to provide</li>
                        </ul>
                    </Subsection>

                    <Subsection title="2.2 Methods of Collection">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Direct input: Information you provide when creating an account, using our services, or contacting us</li>
                            <li>Automated technologies: Cookies, web beacons, and similar technologies</li>
                            <li>Third-party sources: We may receive information from business partners, analytics providers, or advertising networks</li>
                        </ul>
                    </Subsection>
                </Section>

                <Section title="3. Use of Information">
                    <Subsection title="3.1 Primary Purposes">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Providing and improving our AI services</li>
                            <li>Personalizing user experience</li>
                            <li>Communicating with users about our services, updates, and offers</li>
                            <li>Analyzing usage patterns to enhance our products</li>
                            <li>Ensuring the security and integrity of our systems</li>
                            <li>Complying with legal obligations</li>
                        </ul>
                    </Subsection>

                    <Subsection title="3.2 Legal Bases for Processing">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Consent: When you have given explicit permission</li>
                            <li>Contractual necessity: To fulfill our obligations in a contract with you</li>
                            <li>Legitimate interests: When processing is necessary for our legitimate business interests</li>
                            <li>Legal obligation: To comply with applicable laws and regulations</li>
                        </ul>
                    </Subsection>
                </Section>

                <Section title="4. Data Sharing and Disclosure">
                    <Subsection title="4.1 Circumstances for Sharing">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>With your consent</li>
                            <li>To comply with legal obligations</li>
                            <li>To protect our rights, privacy, safety, or property</li>
                            <li>In connection with a business transfer or merger</li>
                            <li>With service providers who assist in our operations</li>
                        </ul>
                    </Subsection>

                    <Subsection title="4.2 Categories of Third-Party Recipients">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Cloud service providers</li>
                            <li>Analytics and advertising partners</li>
                            <li>Payment processors</li>
                            <li>Professional advisors (e.g., lawyers, accountants)</li>
                            <li>Regulatory authorities or government bodies when required by law</li>
                        </ul>
                    </Subsection>
                </Section>

                <Section title="5. Data Security Measures">
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Encryption of data in transit and at rest</li>
                        <li>Regular security audits and penetration testing</li>
                        <li>Access controls and authentication mechanisms</li>
                        <li>Employee training on data protection and security practices</li>
                        <li>Incident response plans</li>
                    </ul>
                    <p className="mt-2">While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>
                </Section>

                <Section title="6. User Rights and Choices">
                    <Subsection title="6.1 GDPR and CCPA Compliance">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Right to access your personal data</li>
                            <li>Right to rectification of inaccurate data</li>
                            <li>Right to erasure ("right to be forgotten")</li>
                            <li>Right to restrict processing</li>
                            <li>Right to data portability</li>
                            <li>Right to object to processing</li>
                            <li>Rights related to automated decision-making and profiling</li>
                        </ul>
                    </Subsection>

                    <Subsection title="6.2 Exercising Your Rights">
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Access your account settings to update or correct your information</li>
                            <li>Contact us using the information provided in Section 10</li>
                            <li>Use the unsubscribe link in our marketing communications</li>
                        </ul>
                        <p className="mt-2">We will respond to your requests within the timeframe specified by applicable law.</p>
                    </Subsection>
                </Section>

                <Section title="7. Children's Privacy">
                    <p>
                        Salesbox does not knowingly collect or solicit personal information from anyone under the age of 13. If we learn that we have collected personal information from a child under 13, we will promptly delete that information. If you believe we might have any information from or about a child under 13, please contact us using the information provided in Section 10.
                    </p>
                </Section>

                <Section title="8. International Data Transfers">
                    <p>
                        As a global company, we may transfer your personal data to countries other than your own. When we transfer personal data internationally, we ensure appropriate safeguards are in place, such as:
                    </p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Standard Contractual Clauses approved by the European Commission</li>
                        <li>Binding Corporate Rules for transfers within our group of companies</li>
                        <li>Privacy Shield certification (where applicable)</li>
                    </ul>
                    <p className="mt-2">
                        By using our services, you consent to your data being transferred to other countries, including those that may have different data protection rules than your country.
                    </p>
                </Section>

                <Section title="9. Changes to the Privacy Policy">
                    <p>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by:
                    </p>
                    <ul className="list-disc pl-5 space-y-1">
                        <li>Posting the updated policy on our website</li>
                        <li>Sending an email to the address associated with your account</li>
                        <li>Displaying a prominent notice within our services</li>
                    </ul>
                    <p className="mt-2">We encourage you to review this policy periodically to stay informed about our data practices.</p>
                </Section>

                <Section title="10. Contact Information">
                    <p>
                        If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
                    </p>
                    <p className="mt-2">
                        <strong>Salesbox</strong><br />
                        Privacy Team<br />
                        Email: <a href="mailto:info@salesbox.io" className="text-blue-600 underline">info@salesbox.io</a><br />
                        Address: 5101 14th Ave. Seattle, WA 98107
                    </p>
                    <p className="mt-4">Last updated: 07/04/2025</p>
                </Section>
            </div>
        </div>
    );
}