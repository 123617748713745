import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
// import SalesBoxLogo from "../../../Assets/SalesBox.svg";
import { routes } from "../../../constants/routes";
import { Twirl as Hamburger } from "hamburger-react";
import Logo from "../../Logo";
import ShopButton from "Components/Shared/ShopButton";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // const handleMenuClick = () => setMenuOpen((prevState) => !prevState);

  return (
    <div className="fixed top-0 z-50 w-full  bg-primary ">
      <div className="w-full py-[18px] max-w-[1400px]   mx-auto flex items-center justify-between px-8 lg:px-20  2xl:px-16 relative z-50">
        <div className="items-center  whitespace-nowrap justify-center gap-12 hidden flex-1 lg:flex">
          <HashLink to={routes.salesSimplified}>
            <p className="text-white font-normal hover:text-secondary transition-all duration-100 hidden lg:block">
              Sales Simplified
            </p>
          </HashLink>
          <HashLink to={routes.campaigns}>
            <p className="text-white font-normal hover:text-secondary transition-all duration-100 hidden lg:block">
              Campaigns
            </p>
          </HashLink>
          <HashLink to={routes.experience}>
            <p className="text-white font-normal hover:text-secondary transition-all duration-100 hidden lg:block">
              Experience
            </p>
          </HashLink>
        </div>
        <div className="lg:flex-1  flex justify-center">
          <Logo />
        </div>

        <div className="flex items-center gap-4 justify-end flex-1">
          <ShopButton title="Shop" />
          <div className="cursor-pointer text-white  rounded-full overflow-hidden hover:bg-black/10 transition-all duration-500">
            <Hamburger size={24} toggled={menuOpen} toggle={setMenuOpen} />
          </div>
        </div>
      </div>

      {/* modal */}
      <div
        onClick={() => setMenuOpen(false)}
        className={`w-screen h-[calc(100vh-5rem)] absolute top-[84px] lg:top-[88px] transition-all duration-500 z-0  ${
          menuOpen
            ? "bg-black/40 pointer-events-auto"
            : "bg-black/0 pointer-events-none"
        }`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={` w-full md:w-[50%] lg:w-[35rem] bg-white h-full absolute  shadow-md transition-all duration-500 ${
            menuOpen
              ? "right-0 pointer-events-auto"
              : "-right-[110%] pointer-events-none"
          }`}
        >
          <HashLink
            to={routes.salesSimplified}
            onClick={() => setMenuOpen(false)}
          >
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Sales Simplified
            </h6>
          </HashLink>
          <HashLink to={routes.campaigns} onClick={() => setMenuOpen(false)}>
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Campaigns OnDemand
            </h6>
          </HashLink>
          <HashLink to={routes.experience} onClick={() => setMenuOpen(false)}>
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Experience
            </h6>
          </HashLink>
          <HashLink to={routes.testimonials} onClick={() => setMenuOpen(false)}>
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Testimonials
            </h6>
          </HashLink>
          <HashLink to={routes.leadership} onClick={() => setMenuOpen(false)}>
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Teams
            </h6>
          </HashLink>
          <HashLink to={routes.salesmrkt} onClick={() => setMenuOpen(false)}>
            <h6 className="text-black px-8 py-5 lg:py-5 text-xl lg:text-2xl font-normal hover:text-primary transition-all duration-100">
              Salesmrkt
            </h6>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
