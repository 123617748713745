import PrivacyPolicy from "pages/PrivacyPolicy";
import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Components/Loading";
import { routes } from "./constants/routes";
import HomeV2 from "./pages/HomeV2";
import NotFoundPage from "./pages/NotFoundPage";
import ScrollToTop from "./services/ScrollToTop";
// lazyLoaders
const NavBar = lazy(() => import("./Components/Navigation/NavBar"));
const Footer = lazy(() => import("./Components/Navigation/Footer"));
const Experience = lazy(() => import("./pages/Experience"));
const Campaigns = lazy(() => import("./pages/Campaigns"));
const HR = lazy(() => import("./pages/services/HR"));
// const Home = lazy(() => import("./pages/Home"));

const Leadership = lazy(() => import("./pages/Leadership"));
const Marketing = lazy(() => import("./pages/services/Marketing"));
// const SalesSimplified = lazy(() => import("./pages/SalesSimplified"));
const SalesSimplified = lazy(() => import("./pages/SalesSimplifiedV2"));
const ProfessionalServices = lazy(() =>
  import("./pages/services/ProfessionalServices")
);
const TechAndSaaS = lazy(() => import("./pages/services/Tech&SaaS"));
const Testimonial = lazy(() => import("./pages/Testimonial"));
const Healthcare = lazy(() => import("./pages/services/Healthcare"));
const FinTech = lazy(() => import("./pages/services/FinTech"));
const FoodAndBeverage = lazy(() =>
  import("./pages/services/ProfessionalServices")
);

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <ScrollToTop>
          <NavBar />
          <Routes>
            <Route path={routes.home} element={<HomeV2 />}></Route>
            <Route
              path={routes.salesSimplified}
              element={<SalesSimplified />}
            ></Route>
            <Route path={routes.campaigns} element={<Campaigns />}></Route>
            <Route path={routes.experience} element={<Experience />}></Route>
            <Route path={routes.testimonials} element={<Testimonial />}></Route>
            <Route path={routes.leadership} element={<Leadership />}></Route>
            <Route path={routes.hr} element={<HR />}></Route>
            <Route path={routes.techSaas} element={<TechAndSaaS />}></Route>
            <Route path={routes.marketing} element={<Marketing />}></Route>
            <Route path={routes.privacyPolicy} element={<PrivacyPolicy />}></Route>
            <Route
              path={routes.professionalServices}
              element={<ProfessionalServices />}
            ></Route>
            <Route path={routes.healthcare} element={<Healthcare />}></Route>
            <Route path={routes.finTech} element={<FinTech />}></Route>
            <Route
              path={routes.foodBeverage}
              element={<FoodAndBeverage />}
            ></Route>
            {/* <Route
					path='/SalesSimplified'
					element={<SalesSimplified />}></Route>
				<Route
					path='/campaigns'
					element={<Campagins />}></Route>
				<Route
					path='/ProductPageExample'
					element={<PP />}></Route> */}
            <Route path={routes.wdec} element={<HomeV2 />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
    // <div className='h-full w-full'>
    // 	<NavBar />
    // 	<LandingPage />
    // 	<UnderLanding />
    // 	<UnderBanner />
    // 	<LeadingBrandsone />
    // 	<UnderLogos />
    // 	<Footer />
    // </div>
  );
}

export default App;
