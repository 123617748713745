import { externalLinks } from "constants/external-links";
import { useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import acceptedPayments from "../../../Assets/HomePageAssets/accepted-payments.png";
import downArrow from "../../../Assets/arrow-down.svg";
import trust from "../../../Assets/certified.png";
import logo from "../../../Assets/footer-logo.png";
import facebook from "../../../Assets/icons/facebook.svg";
import instagram from "../../../Assets/icons/instagram.svg";
import linkedin from "../../../Assets/icons/linkedin.svg";
import emailIcon from "../../../Assets/icons/mail.png";
import youtube from "../../../Assets/icons/youtube.svg";
import linkedinBlue from "../../../Assets/linkedin-blue.png";
import trustMobile from "../../../Assets/trusted-mobile.png";
import { routes } from "../../../constants/routes";
import { sendEmail } from "../../../services/email";
const Footer = () => {
  const formRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState();

  const isActive = (index) => activeIndex === index;

  const footerSections = [
    {
      title: "Let’s Talk",
      content: (
        <div className="mt-3 flex flex-col gap-4 ">
          <a href="tel:+1-888-920-GROW" className="text-base font-normal group">
            <h6 className="text-white group-hover:text-secondary transition-all">
              Customer Service
            </h6>
            <h6 className="text-white group-hover:text-secondary transition-all">
              1-888-920-GROW
            </h6>
          </a>
          <a
            href="tel:+1 (206)278-9222"
            className="text-base font-normal group"
          >
            <h6 className="text-white group-hover:text-secondary transition-all mt-4">
              Office Phone
            </h6>
            <h6 className="text-white group-hover:text-secondary transition-all">
              +1 (206)278-9222
            </h6>
          </a>
          <div className="text-base font-normal">
            <h6 className="text-white">5101 14th Ave NW</h6>
            <h6 className="text-white">Suite 200</h6>
            <h6 className="text-white">Seattle, WA, 98107</h6>
          </div>
        </div>
      ),
      id: 0,
    },
    {
      title: "Key Topics",
      content: (
        <div className="mt-3 text-white flex flex-col gap-3 font-normal">
          <HashLink to={routes.home}>
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Home
            </h6>
          </HashLink>
          <HashLink to={routes.salesSimplified}>
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Sales Simplified
            </h6>
          </HashLink>
          <HashLink to={routes.campaigns}>
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Campaigns OnDemand
            </h6>
          </HashLink>
          <HashLink to={routes.testimonials}>
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Testimonials
            </h6>
          </HashLink>
          <HashLink to={routes.leadership}>
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Leadership Team
            </h6>
          </HashLink>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white hover:text-secondary transition-all text-base my-2">
              Shop
            </h6>
          </a>
        </div>
      ),
      id: 1,
    },
    {
      title: "Solutions",
      content: (
        <div className="mt-3 flex flex-col gap-3 text-white font-normal">
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Campaigns onDemand
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Top Services
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Promotions
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Industry-Tailored Services
            </h6>
          </a>
          <a
            href={externalLinks.social.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center gap-2">
              <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
                Find us on
              </h6>
              <img src={linkedinBlue} alt="LinkedIn" />
            </div>
          </a>
        </div>
      ),
      id: 2,
    },
    {
      title: "Campaigns",
      content: (
        <div className="mt-3 flex flex-col gap-3  text-white font-normal">
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Sales Subscription
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              1 Month
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              3 Months
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              6 Months
            </h6>
          </a>
          <a
            href={routes.marketplace}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6 className="text-normal text-white cursor-pointer hover:text-secondary transition-all text-base my-2">
              Trial
            </h6>
          </a>
        </div>
      ),
      id: 3,
    },
  ];

  return (
    <footer className="bg-black w-full">
      <div className="w-full mx-auto max-w-[1400px] px-8 lg:px-20 py-4 md:py-12 lg:pt-20 lg:pb-12">
        <div className="lg:pt-10 lg:pb-24 md:pb-10 flex flex-col md:flex-row md:flex-wrap md:gap-10 justify-between">
          {/* Footer Sections */}
          {footerSections.map((section) => (
            <div className="space-y-2 md:space-y-8" key={section.id}>
              {/* Section Header with Expandable Feature */}
              <div
                onClick={() =>
                  setActiveIndex(isActive(section.id) ? null : section.id)
                }
                className={`flex items-center justify-between ${section.id !== 3 && "border-b border-[#707070 "
                  } md:border-none ] py-3 md:py-0`}
              >
                <h3 className="text-xl text-white font-bold">
                  {section.title}
                </h3>
                <img
                  src={downArrow}
                  alt="downArrow"
                  className={`transition-all duration-500 ease-in-out ${isActive(section.id) ? "transform rotate-180" : ""
                    } md:hidden w-3 h-3 cursor-pointer`}
                />
              </div>
              {/* Section Content */}
              <div
                className={`flex flex-col gap-4 overflow-hidden transition-all duration-500 ease-in-out transform ${isActive(section.id)
                  ? "max-h-96 opacity-100 translate-y-0"
                  : "max-h-0 opacity-0 -translate-y-4"
                  } md:max-h-96 md:opacity-100 md:translate-y-0`}
              >
                {section.content}
              </div>
            </div>
          ))}

          {/* Email Input and Trusted Section */}
          <div className="my-10 w-full md:space-y-4 md:mx-auto  flex flex-col 2xl:mx-0 md:my-0 text-center lg:text-start">
            <div className="lg:mx-auto">
              <h3 className="lg:text-xl md:mb-0 text-white font-bold">
                Receive News & Updates
              </h3>
              <form
                className="lg:mt-4  mt-2 w-full rounded-lg px-6 lg:px-0"
                ref={formRef}
                onSubmit={(e) => {
                  e.preventDefault();
                  sendEmail(
                    e,
                    formRef.current,
                    process.env.REACT_APP_EMAIL_PROMO_TEMPLATE_ID
                  );
                }}
              >
                <input
                  type="text"
                  placeholder="Enter your Email"
                  className="outline-none rounded-[50px] bg-white py-3 px-6 placeholder:text-[#222222] w-full text-black"
                  name="form_email"
                />
                <button
                  className="absolute p-2 -translate-x-12 translate-y-2"
                  type="submit"
                >
                  <img
                    className="hover:scale-110 duration-150 ease-in hover:animate-pulse"
                    src={emailIcon}
                    alt="email icon"
                  />
                </button>
              </form>

              <div className="hidden  justify-center  h-full  lg:flex">
                <img
                  src={trust}
                  alt="Trusted Brands"
                  width={"40%"}
                  className="xl:mx-auto self-end -mt-10"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Social Icons for Mobile */}
        <div className="flex md:hidden justify-center  my-2 lg:my-10 items-center gap-6 text-white">
          <a
            href={externalLinks.social.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin" className="w-6" />
          </a>
          <a
            href={externalLinks.social.youtube}
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" className="w-6" />
          </a>
          <a
            href={externalLinks.social.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" className="w-6" />
          </a>
          <a
            href={externalLinks.social.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" className="w-6" />
          </a>
        </div>

        {/* Trusted Section for Mobile */}
        <div className="w-full  mt-8 lg:hidden mb-12">
          <img
            src={trustMobile}
            alt="Trusted Partner"
            className="mx-auto w-[40%]"
          />
        </div>

        <hr
          className="w-full lg:border-b  border-[#707070] lg:my-7"
          color="#707070"
        />

        {/* Footer Bottom Section */}
        <div className="flex items-center   justify-center md:justify-between flex-wrap  py-10 px-5 lg:px-10">
          <div className="flex flex-col justify-center md:justify-between ">
            <a
              href="/"
              className="flex  flex-wrap mx-auto justify-center  lg:justify-start flex-1 lg:gap-4 items-center"
            >
              <img
                src={logo}
                alt="Salesmrkt Logo"
                loading="lazy"
                className="w-[30%] lg:w-[50%]"
              />
              <p className="text-white text-xs lg:text-md  mt-1 lg:mt-5 whitespace-nowrap  ">
                © {new Date().getFullYear()}, Salesbox. All Rights Reserved.
              </p>
            </a>
            <a href="/privacy-policy" className="mt-5 text-sm w-fit underline text-white hover:text-blue">
              Privacy Policy
            </a>
          </div>
          <div className="hidden md:flex   justify-center flex-1 items-center gap-4 mt-5 text-white">
            <a
              href={externalLinks.social.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" className="w-8" />
            </a>
            <a
              href={externalLinks.social.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" className="w-8" />
            </a>
            <a
              href={externalLinks.social.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" className="w-8" />
            </a>
            <a
              href={externalLinks.social.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" className="w-8" />
            </a>
          </div>
          <div className="flex-1 lg:justify-end  justify-center  flex items-center mt-1 lg:mt-5">
            <img
              src={acceptedPayments}
              alt="Accepted Payments"
              className="min-w-[100px] max-w-[150px] mx-auto lg:ml-0 lg:min-w-min lg:mx-0"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
