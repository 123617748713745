import { routes } from "constants/routes";
import ShopIcon from "../../Assets/icons/shop.svg";
const ShopButton = ({ title, handleClick }) => {
  return (
    <a
      href={routes.salesmrkt}
      onClick={handleClick}
      target={"_blank"}
      rel="noreferrer"
      className="bg-primary-gradient  hidden md:flex rounded-[90px] hover:bg-secondary-gradient transition-all font-medium text-white text-[1.2rem]  items-center gap-4 p-3 px-5"
    >
      <img src={ShopIcon} />
      {title}
    </a>
  );
};

export default ShopButton;
